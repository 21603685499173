import React from 'react';
import { Modal } from '@entur/modal';
import { GridContainer, GridItem } from '@entur/grid';
import { Paragraph } from '@entur/typography';
import { NegativeButton } from '@entur/button';
import { useAuth0 } from '../auth';
import { BannerAlertBox } from '@entur/alert';
import { useMutation } from 'react-query';
import { patchUserPermissions } from '../api/API';
import { IAuth } from '../api/abstractAPI';

type DeletePermissionProps = {
    cluster: string;
    user: string;
    topicPermission: Components.Schemas.TopicPermission;
    isOpen: boolean;
    close: () => void;
};

const DeletePermission = ({ cluster, user, topicPermission, isOpen, close }: DeletePermissionProps) => {
    const auth: IAuth = useAuth0();

    const aclConsumerUpdate: Components.Schemas.AclUpdate = {
        principal: user,
        permission: 'CONSUMER',
        operation: topicPermission.allowConsume ? 'ALLOW' : 'DENY',
        createOrDelete: 'DELETE'
    };
    const aclProducerUpdate: Components.Schemas.AclUpdate = {
        principal: user,
        permission: 'PRODUCER',
        operation: topicPermission.allowProduce ? 'ALLOW' : 'DENY',
        createOrDelete: 'DELETE'
    };
    const aclUpdateList = [aclConsumerUpdate, aclProducerUpdate];

    const mutation = useMutation<void, Error>(
        () => patchUserPermissions(auth, cluster, topicPermission.topicName, aclUpdateList),
        {
            onSuccess: close
        }
    );

    return (
        <Modal open={isOpen} onDismiss={close} title="Confirm delete permission" size="medium">
            <GridContainer spacing="medium">
                <GridItem small={12}>
                    <Paragraph>Are you sure that you want to delete this user permission?</Paragraph>
                </GridItem>
                {mutation.isError && (
                    <GridItem small={12}>
                        <BannerAlertBox variant="error">{mutation.error?.message}</BannerAlertBox>
                    </GridItem>
                )}
                <GridItem small={3}>
                    <NegativeButton disabled={false} loading={mutation.isLoading} onClick={() => mutation.mutate()}>
                        Delete
                    </NegativeButton>
                </GridItem>
            </GridContainer>
        </Modal>
    );
};

export default DeletePermission;
