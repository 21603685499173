import React from 'react';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import { Link as EnturLink } from '@entur/typography';
import { GridItem } from '@entur/grid';
import { Link, useParams } from 'react-router-dom';

type KafkaUserListProps = {
    kafkaUserList: Components.Schemas.KafkaUserList;
    filter: string;
    clusterPlatform: string;
};

const KafkaUserList = ({ kafkaUserList, filter, clusterPlatform }: KafkaUserListProps) => {
    const { cluster } = useParams<{ cluster: string }>();

    let kafkaUserElements = kafkaUserList.users
        .filter((userInfo: Components.Schemas.KafkaUserInfo) => userInfo.userName.includes(filter))
        .map((userInfo: Components.Schemas.KafkaUserInfo) => (
            <TableRow key={userInfo.userName}>
                <DataCell>
                    <EnturLink as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users/${userInfo.userName}`}>
                        {userInfo.userName}
                    </EnturLink>
                </DataCell>
            </TableRow>
        ));

    return (
        <GridItem small={9}>
            <Table>
                <TableHead>
                    <TableRow>
                        <HeaderCell>{KAFKA_USERS_TABLE_HEADER}</HeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>{kafkaUserElements}</TableBody>
            </Table>
        </GridItem>
    );
};

export const KAFKA_USERS_TABLE_HEADER = 'Kafka User';
export default KafkaUserList;
