import { GridContainer, GridItem } from '@entur/grid';
import { Heading4, Label, Link as EnturLink, Paragraph } from '@entur/typography';
import { Link } from 'react-router-dom';
import { Tooltip } from '@entur/tooltip';
import { IconButton } from '@entur/button';
import { DeleteIcon, EditIcon } from '@entur/icons';
import { ExpandablePanel } from '@entur/expand';
import { formatRetentionTime } from '../ListTopics';
import AlterAcl from './AlterAcl';
import React, { useState } from 'react';
import { colors } from '@entur/tokens';
import DeleteTopic from '../../components/DeleteTopic';
import EditCleanupPolicy from './EditCleanupPolicy';
import EditRetentionTimeMs from './EditRetentionTimeMs';
import EditTeamOwnership from './EditTeamOwnership';
import { useQuery } from 'react-query';
import { getAuth0Teams, getUserIsTeamAdmin } from '../../api/API';
import { useAuth0 } from '../../auth';
import { GrafanaLink } from '../../components/GrafanaLink';

type TopicDetailsProps = {
    clusterPlatform: string;
    cluster: string;
    topicDetails: Components.Schemas.TopicDetails;
};

const TopicDetails = ({ clusterPlatform, cluster, topicDetails }: TopicDetailsProps) => {
    const topicName = topicDetails.info.name;

    const auth = useAuth0();
    const authTeamsQuery = useQuery(`authTeam-${cluster}`, () => getAuth0Teams(auth));
    const queriedTeams = authTeamsQuery.data ? authTeamsQuery.data.teams : [];
    const userTeamIsUnknown = queriedTeams.length === 0;
    const userIsDataAdmin = queriedTeams.includes('DATA');
    const userIsTeamAdminQuery = useQuery('userIsTeamAdmin', () => getUserIsTeamAdmin(auth));
    const allowedToModify =
        userIsDataAdmin || userIsTeamAdminQuery.data || queriedTeams.includes(topicDetails.info.team);

    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [editCleanupPolicyModalIsOpen, setEditCleanupPolicyModalIsOpen] = useState(false);
    const [editRetentionModalIsOpen, setEditRetentionModalIsOpen] = useState(false);
    const [editTeamOwnershipModalIsOpen, setEditTeamOwnershipModalIsOpen] = useState(false);

    const disabledEditACLsButton = (userTeamIsUnknown || !allowedToModify) && (
        <IconButton as={'button'} disabled={userTeamIsUnknown || !allowedToModify} onClick={() => undefined}>
            <EditIcon />
        </IconButton>
    );
    const topicPermissionACLs = (
        <GridItem small={6}>
            <Heading4>Permissions:</Heading4>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph margin="none">
                    <Label>Producers: </Label>
                    {topicDetails.aclConfiguration?.producerPrincipals?.map((p: string, i: number) => (
                        <span key={p+i}>
                            <EnturLink key={p} as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users/${p}`}>
                                {p}
                            </EnturLink>
                            {i < topicDetails.aclConfiguration!!.producerPrincipals!!.length - 1 ? (
                                <span>, </span>
                            ) : null}
                        </span>
                    ))}
                </Paragraph>
                {allowedToModify && <AlterAcl cluster={cluster} topic={topicName} permission={'PRODUCER'} />}
                {disabledEditACLsButton}
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Paragraph margin="none">
                    <Label>Consumers: </Label>
                    {topicDetails.aclConfiguration?.consumerPrincipals?.map((p: string, i: number) => (
                        <span key={p}>
                            <EnturLink key={p} as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users/${p}`}>
                                {p}
                            </EnturLink>
                            {i < topicDetails.aclConfiguration!!.consumerPrincipals!!.length - 1 ? (
                                <span>, </span>
                            ) : null}
                        </span>
                    ))}
                </Paragraph>
                {allowedToModify && <AlterAcl cluster={cluster} topic={topicName} permission={'CONSUMER'} />}
                {disabledEditACLsButton}
            </div>
        </GridItem>
    );

    return (
        <GridItem small={12}>
            <DeleteTopic
                clusterPlatform={clusterPlatform}
                cluster={cluster}
                topic={topicName}
                isOpen={deleteModalIsOpen}
                close={() => setDeleteModalIsOpen(false)}
            />
            <EditCleanupPolicy
                cluster={cluster}
                topic={topicName}
                existingPolicy={topicDetails.info.cleanupPolicy}
                open={editCleanupPolicyModalIsOpen}
                close={() => setEditCleanupPolicyModalIsOpen(false)}
            />
            <EditRetentionTimeMs
                existingRetentionTimeMs={topicDetails.info.retention}
                open={editRetentionModalIsOpen}
                close={() => setEditRetentionModalIsOpen(false)}
                topic={topicName}
                cluster={cluster}
            />
            <EditTeamOwnership
                cluster={cluster}
                topicName={topicName}
                team={topicDetails.info.team}
                open={editTeamOwnershipModalIsOpen}
                close={() => setEditTeamOwnershipModalIsOpen(false)}
                allowedToModify={allowedToModify}
                updateSuccess={(newTeam: Components.Schemas.Team) => (topicDetails.info.team = newTeam)}
            />
            <div>
                <GridItem
                    small={1}
                    style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Tooltip placement="bottom" content="Delete topic">
                        <IconButton
                            as={'button'}
                            disabled={!allowedToModify}
                            onClick={() => setDeleteModalIsOpen(true)}
                            style={{ display: 'inline' }}
                        >
                            <DeleteIcon style={{ color: colors.validation.lava }} />
                        </IconButton>
                    </Tooltip>
                </GridItem>
            </div>
            <ExpandablePanel defaultOpen title="Topic Details">
                <GridContainer spacing="large" rowSpacing="medium">
                    <GridItem small={6}>
                        <Heading4>Configuration:</Heading4>
                        <div>
                            <Label>Replication factor:</Label> {topicDetails.info.replicationFactor}
                        </div>
                        <div>
                            <Label>Partitions:</Label> {topicDetails.info.partitions}
                        </div>
                        <div>
                            <Label>Retention:</Label> {formatRetentionTime(topicDetails.info.retention)}
                            <Tooltip placement="bottom" content="Edit">
                                <IconButton
                                    as={'button'}
                                    disabled={!allowedToModify}
                                    onClick={() => setEditRetentionModalIsOpen(true)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Label>Cleanup policy:</Label> {topicDetails.info.cleanupPolicy}
                            <Tooltip placement="bottom" content="Edit">
                                <IconButton
                                    as={'button'}
                                    disabled={!allowedToModify}
                                    onClick={() => setEditCleanupPolicyModalIsOpen(true)}
                                >
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </GridItem>
                    <GridItem small={6}>
                        <Heading4>Metadata:</Heading4>
                        <Paragraph margin="none">
                            <Label>Number of messages:</Label> {topicDetails.messageCount}
                        </Paragraph>
                        <Label>Owned by team:</Label> {topicDetails.info.team}
                        <Tooltip placement="bottom" content="Edit">
                            <IconButton
                                as={'button'}
                                style={{ display: 'inline' }}
                                disabled={!allowedToModify}
                                onClick={() => setEditTeamOwnershipModalIsOpen(true)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Paragraph margin="none">
                            <Label>Description:</Label> Not implemented
                        </Paragraph>
                        <br />
                        <GrafanaLink cluster={cluster} topic={topicName} />
                    </GridItem>

                    {topicPermissionACLs}
                </GridContainer>
            </ExpandablePanel>
        </GridItem>

    );
};

export default TopicDetails;
