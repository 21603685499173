import { ExpandablePanel } from '@entur/expand';
import { BannerAlertBox } from '@entur/alert';
import MessagePreview, { MessagePreviewType } from './MessagePreview';
import { GridItem } from '@entur/grid';
import React from 'react';

type MessagePreviewViewProps = {
    valueSchemaType?: string;
    messageCount: number;
    cluster: string;
    topicName: string;
};

const MessagePreviewPanel = ({ valueSchemaType, messageCount, cluster, topicName }: MessagePreviewViewProps) => {
    return (
        <GridItem small={12}>
            <ExpandablePanel title="Preview Messages">
                {!valueSchemaType || valueSchemaType !== 'AVRO' ? (
                    <BannerAlertBox variant={'info'}>Preview not available for non-Avro messages.</BannerAlertBox>
                ) : messageCount ? (
                    <MessagePreview
                        cluster={cluster}
                        topicName={topicName}
                        messagePreviewPartition={null}
                        messagePreviewType={MessagePreviewType.Table}
                    />
                ) : (
                    <BannerAlertBox variant={'info'}>There are no messages on this topic.</BannerAlertBox>
                )}
            </ExpandablePanel>
        </GridItem>
    );
};

export default MessagePreviewPanel;
