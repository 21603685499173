import React from 'react';
import { Heading4, Paragraph } from '@entur/typography';
import { ReactComponent as GrafanaSvg } from './grafana_icon.svg';

type GrafanaConnectorLinkProps = {
    cluster: string;
    connectorName: string;
};

export const GrafanaConnectorLink = ({ cluster, connectorName }: GrafanaConnectorLinkProps) => {
    const project = cluster.includes('prod') ? 'entur-prod' : 'entur-test';
    const prometheusGroup = cluster.includes('prod') ? 'kub-ent-prd-001' : 'kub-ent-tst-001';
    return (
        <Heading4 as={Paragraph}>
            <a
                target="_blank"
                rel="noreferrer"
                href={`https://grafana.entur.org/d/m79OxN9Vz/aiven-kafka-connect?orgId=1&refresh=30s&var-connector=${connectorName}&var-host=All&var-service=${cluster}-connect&var-project=${project}&var-pg=${prometheusGroup}`}
            >
                Grafana
                <GrafanaSvg style={{ width: '1.5rem', height: '1rem' }} />
            </a>
        </Heading4>
    );
};
