type Environment = 'DEV' | 'STAGING' | 'PRODUCTION';

function getEnvironment(): Environment {
    const location = window.location.origin;
    if (location.includes('localhost')) return 'DEV';
    if (location.includes('.staging.')) return 'STAGING';
    return 'PRODUCTION';
}

export function getAPIUrl(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'http://localhost:3000';
        case 'STAGING':
            return 'https://api.staging.entur.io/kafka-admin-api';
        default:
            return 'https://api.entur.io/kafka-admin-api';
    }
}

export function getAPIVersionPath(): String {
    return '/v1/';
}

export function getDataDocsUrl(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'http://localhost:3000/docs/streaming-kafka/';
        case 'STAGING':
            return 'https://docs.data.staging.entur.no/docs/streaming-kafka/';
        case 'PRODUCTION':
            return 'https://docs.data.entur.no/docs/streaming-kafka/';
    }
}

export function getClientId(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'BJO4RtnHplZh2CXiqW5qT1Hu1yfT5WUX';
        case 'STAGING':
            return '9tNxyksNdRJ4E3nQPOkxeisOo6wO8hSh';
        default:
            return 'AinLPWCcx1IhSahsMnPXUwR35MRHil9j';
    }
}

export function getDomain(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'partner.dev.entur.org';
        case 'STAGING':
            return 'partner.staging.entur.org';
        default:
            return 'partner.entur.org';
    }
}

export function getAudience(): string {
    switch (getEnvironment()) {
        case 'DEV':
            return 'https://api.dev.entur.io/kafka-admin-api';
        case 'STAGING':
            return 'https://api.staging.entur.io/kafka-admin-api';
        default:
            return 'https://api.entur.io/kafka-admin-api';
    }
}
