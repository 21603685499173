import { getAPIUrl, getAPIVersionPath } from '../config';

export const API_URL = getAPIUrl();
export const API_VERSION_PATH = getAPIVersionPath();

export interface IAuth {
    getTokenSilently: () => Promise<String>;

    [propName: string]: any;
}

export async function createHeaders(auth: IAuth) {
    const accessToken = await auth.getTokenSilently();
    return {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
}

export async function doGetRequestForUrl<T>(auth: IAuth, url: string, expectedResponse = 200): Promise<T> {
    const headers = await createHeaders(auth);
    return fetch(url, {
        headers: headers
    }).then(async (response) => {
        if (response.status === expectedResponse) {
            return response.json();
        } else {
            throw new Error(await response.json().then((resp) => resp.message));
        }
    });
}

export async function doGetRequest<T>(path: string, auth: IAuth, expectedResponse = 200): Promise<T> {
    const url = `${API_URL}${API_VERSION_PATH}${path}`;
    return doGetRequestForUrl(auth, url, expectedResponse);
}

export async function doPostRequestToUrl<T>(
    url: string,
    auth: IAuth,
    stringifiedPayload: string,
    expectedResponse = 201
): Promise<T> {
    const headers = await createHeaders(auth);
    return fetch(url, {
        headers,
        method: 'POST',
        body: stringifiedPayload
    }).then(async (response) => {
        if (response.status === expectedResponse) {
            return response.json();
        } else {
            const error = await response.json();
            throw new Error(error.message);
        }
    });
}
