import React, { useState } from 'react';
import { Modal } from '@entur/modal';
import { GridContainer, GridItem } from '@entur/grid';
import { Paragraph } from '@entur/typography';
import { InputGroup, TextField } from '@entur/form';
import { NegativeButton } from '@entur/button';
import { useAuth0 } from '../auth';
import { deleteTopic } from '../api/API';
import { useHistory } from 'react-router-dom';
import { BannerAlertBox } from '@entur/alert';
import { useMutation } from 'react-query';
import { IAuth } from '../api/abstractAPI';

type DeleteTopicProps = {
    clusterPlatform: string;
    cluster: string;
    topic: string;
    isOpen: boolean;
    close: () => void;
};

const DeleteTopic = (props: DeleteTopicProps) => {
    const auth: IAuth = useAuth0();
    const history = useHistory();

    const [input, setInput] = useState('');

    const mutation = useMutation<void, Error>(() => deleteTopic(auth, props.cluster, props.topic), {
        onSuccess: (_) => history.push(`/clusters/${props.clusterPlatform}/${props.cluster}`)
    });

    return (
        <Modal open={props.isOpen} onDismiss={props.close} title="Confirm topic deletion" size="medium">
            <GridContainer spacing="medium">
                <GridItem small={12}>
                    <Paragraph>Enter the name of the topic to confirm deletion:</Paragraph>
                </GridItem>
                {mutation.isError && (
                    <GridItem small={12}>
                        <BannerAlertBox variant="error">{mutation.error?.message}</BannerAlertBox>
                    </GridItem>
                )}
                <GridItem small={9}>
                    <InputGroup
                        feedback={input === props.topic ? 'Ok' : "Topic names don't match"}
                        variant={input === props.topic ? 'success' : 'error'}
                    >
                        <TextField
                            placeholder="Topic name"
                            width="fluid"
                            value={input}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)}
                        />
                    </InputGroup>
                </GridItem>
                <GridItem small={3}>
                    <NegativeButton
                        disabled={input !== props.topic}
                        loading={mutation.isLoading}
                        onClick={() => mutation.mutate()}
                    >
                        Delete
                    </NegativeButton>
                </GridItem>
            </GridContainer>
        </Modal>
    );
};

export default DeleteTopic;
