import React from 'react';
import { DataCell, Table, TableBody, TableRow } from '@entur/table';
import { useQuery } from 'react-query';
import { Loader } from '@entur/loader';
import { getKafkaUserDetails } from '../api/API';
import { useAuth0 } from '../auth';
import { useParams } from 'react-router-dom';
import TopicPermission from './TopicPermission';
import { Heading2 } from '@entur/typography';
import { IAuth } from '../api/abstractAPI';

type KafkaUserDetailsProps = {
    user: string;
};

const KafkaUserDetails = ({ user }: KafkaUserDetailsProps) => {
    const { cluster } = useParams<{ cluster: string }>();
    const auth: IAuth = useAuth0();
    const kafkaUserDetailsQuery = useQuery(`kafkaUserDetails-${user}`, () => getKafkaUserDetails(auth, cluster, user));

    if (!kafkaUserDetailsQuery.isSuccess) {
        return <Loader>Loading user details</Loader>;
    } else {
        const topicPermissions = kafkaUserDetailsQuery.data.topicPermissions;
        const permissions =
            topicPermissions.length === 0 ? (
                <TableRow>
                    <DataCell>{NO_PERMISSIONS_FOUND_TEXT}</DataCell>
                </TableRow>
            ) : (
                topicPermissions.map((tp) => (
                    <TableRow key={tp.topicName}>
                        <TopicPermission topicPermission={tp} user={user} />
                    </TableRow>
                ))
            );

        return (
            <Table>
                <TableBody>
                    <TableRow>
                        <Heading2 as={DataCell} small={8} style={{ fontWeight: 'bold' }}>
                            Principal: {user}
                        </Heading2>
                    </TableRow>
                    {permissions}
                </TableBody>
            </Table>
        );
    }
};

export const NO_PERMISSIONS_FOUND_TEXT = 'No permissions found';
export default KafkaUserDetails;
