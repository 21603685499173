import React, { useState } from 'react';
import { Modal } from '@entur/modal';
import { Dropdown } from '@entur/dropdown';
import { Switch } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import { useChangeConfig } from '../../api/API';
import { BannerAlertBox } from '@entur/alert';

type EditCleanupPolicyProps = {
    existingPolicy: 'DELETE' | 'COMPACT';
    open: boolean;
    close: () => void;
    topic: string;
    cluster: string;
};

const EditCleanupPolicy = (props: EditCleanupPolicyProps) => {
    const [cleanupPolicy, setCleanupPolicy] = useState<'DELETE' | 'COMPACT'>(props.existingPolicy);
    const [warningAccepted, setWarningAccepted] = useState(false);

    const editMutation = useChangeConfig(props.cluster, props.topic, props.close);

    return (
        <Modal size={'small'} title={'Edit topic cleanup policy'} open={props.open} onDismiss={props.close}>
            <Dropdown
                items={['DELETE', 'COMPACT']}
                label="Cleanup policy"
                placeholder="Cleanup policy"
                value={cleanupPolicy}
                onChange={(x) => setCleanupPolicy(x?.value === 'COMPACT' ? 'COMPACT' : 'DELETE')}
            />
            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <Switch checked={warningAccepted} onChange={() => setWarningAccepted(!warningAccepted)} /> I understand
                that changing the cleanup policy can cause messages to be deleted.
            </div>
            {}
            {editMutation.isError && <BannerAlertBox variant={'error'}>{editMutation.error?.message}</BannerAlertBox>}
            <PrimaryButton
                className={'right'}
                disabled={!warningAccepted}
                loading={editMutation.isLoading}
                onClick={() => editMutation.mutate({ cleanupPolicy })}
            >
                Save
            </PrimaryButton>
        </Modal>
    );
};

export default EditCleanupPolicy;
