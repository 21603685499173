import React, { useState } from 'react';
import { IconButton, PrimaryButton, SecondaryButton } from '@entur/button';
import { EditIcon } from '@entur/icons';
import { Tooltip } from '@entur/tooltip';
import { useAuth0 } from '../../auth';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getPrincipals, patchUserPermissions } from '../../api/API';
import { Modal } from '@entur/modal';
import { Dropdown } from '@entur/dropdown';
import { SubLabel } from '@entur/typography';

function usePrincipalList(cluster: string) {
    const auth = useAuth0();
    return useQuery(['principals', cluster], () => getPrincipals(cluster, auth), {
        staleTime: 1000 * 60 * 5
    });
}

function useAclMutation(cluster: string, topic: string, closeModalFn: () => void) {
    const auth = useAuth0();
    const queryClient = useQueryClient();
    return useMutation<void, Error, Components.Schemas.AclUpdate>(
        (aclUpdate) => patchUserPermissions(auth, cluster, topic, [aclUpdate]),
        {
            onSuccess: (_) => {
                closeModalFn();
                queryClient.invalidateQueries(`topicDetails-${cluster}-${topic}`);
            }
        }
    );
}

type AlterAclProps = {
    permission: 'CONSUMER' | 'PRODUCER';
    cluster: string;
    topic: string;
};

type AllowOrDeny = 'ALLOW' | 'DENY';

const AlterAcl = (props: AlterAclProps) => {
    const principals = usePrincipalList(props.cluster);
    const [modalOpen, setModalOpen] = useState(false);
    const [principal, setPrincipal] = useState<string>('');
    const [operation, setOperation] = useState<AllowOrDeny | null>(null);

    const mutation = useAclMutation(props.cluster, props.topic, () => {
        setModalOpen(false);
        setPrincipal('');
        setOperation(null);
    });

    return (
        <>
            <Tooltip placement={'right'} content={`Update ${props.permission} permissions for a principal`}>
                <IconButton
                    onClick={() => {
                        setModalOpen(true);
                        setPrincipal('');
                        setOperation(null);
                    }}
                >
                    <EditIcon />
                </IconButton>
            </Tooltip>
            <Modal
                size={'small'}
                open={modalOpen}
                onDismiss={() => setModalOpen(false)}
                title={`Update ${props.permission} permission`}
            >
                {principals.data && (
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                            gap: '2rem 0.5rem'
                        }}
                    >
                        <Dropdown
                            items={principals.data.principals}
                            searchable
                            placeholder={'Principal'}
                            style={{ gridColumnStart: 'span 3' }}
                            onChange={(selectedItem) => setPrincipal(selectedItem?.value || '')}
                        />
                        <Dropdown
                            items={['ALLOW', 'DENY']}
                            placeholder={'Permission'}
                            style={{ gridColumnStart: 'span 3' }}
                            onChange={(selectedItem) => setOperation((selectedItem?.value as AllowOrDeny) || 'DENY')}
                        />
                        <SubLabel style={{ gridColumnStart: 'span 3' }}>
                            ACLs will be added at the topic level. Group is set to *.
                        </SubLabel>
                        <SecondaryButton
                            style={{
                                gridColumn: '2',
                                justifySelf: 'end'
                            }}
                            onClick={() => setModalOpen(false)}
                        >
                            Close
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={principal === '' || operation === null}
                            loading={mutation.isLoading}
                            onClick={() =>
                                mutation.mutate({
                                    operation: operation as AllowOrDeny,
                                    permission: props.permission,
                                    createOrDelete: 'CREATE',
                                    principal
                                })
                            }
                        >
                            Save
                        </PrimaryButton>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default AlterAcl;
