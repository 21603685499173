import { useAuth0 } from '../auth';
import { Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getKafkaUserList } from '../api/API';
import { Loader } from '@entur/loader';
import React, { useState } from 'react';
import KafkaUserList from '../components/KafkaUserList';
import { GridContainer, GridItem } from '@entur/grid';
import { Heading3 } from '@entur/typography';
import { BreadcrumbItem, BreadcrumbNavigation } from '@entur/menu';
import { TextField } from '@entur/form';
import { SearchIcon } from '@entur/icons';
import { useWindowSize } from '../convenience';

const KafkaUsers = ({ clusterPlatform }: { clusterPlatform: string }) => {
    const auth = useAuth0();
    const { cluster } = useParams<{ cluster: string }>();
    const [width] = useWindowSize();
    const isSmall = () => width < 1100;

    const kafkaUserListQuery = useQuery('kafkaUserList', () => getKafkaUserList(auth, cluster));
    const [filter, setFilter] = useState('');

    if (!kafkaUserListQuery.isSuccess) {
        return <Loader>Loading</Loader>;
    }

    let kafkaUserList = kafkaUserListQuery.data;
    return (
        <GridContainer rowSpacing="medium" spacing="large">
            <GridItem small={12}>
                <Heading3>
                    <BreadcrumbNavigation>
                        <BreadcrumbItem as={Link} to="/clusters">
                            {clusterPlatform}
                        </BreadcrumbItem>
                        <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}`}>
                            {cluster}
                        </BreadcrumbItem>
                        <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users`}>
                            users
                        </BreadcrumbItem>
                    </BreadcrumbNavigation>
                </Heading3>
            </GridItem>
            <GridItem small={isSmall() ? 12 : 5}>
                <TextField
                    placeholder="Filter by username"
                    prepend={<SearchIcon inline />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFilter(e.target.value);
                    }}
                />
            </GridItem>

            <KafkaUserList kafkaUserList={kafkaUserList} filter={filter} clusterPlatform={clusterPlatform} />
        </GridContainer>
    );
};

export default KafkaUsers;
