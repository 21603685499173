import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Auth0Provider } from './auth';
import history from './utils/history';
import { getAudience, getClientId, getDomain } from './config';

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState: any): void => {
    history.push(appState && appState.targetUrl ? appState.targetUrl : '/');
};

ReactDOM.render(
    <React.StrictMode>
        <Auth0Provider
            domain={getDomain()}
            client_id={getClientId()}
            redirect_uri={`${window.location.origin}`}
            audience={getAudience()}
            advancedOptions={{ defaultScope: 'openid profile email teams' }}
            cacheLocation="localstorage"
            // @ts-ignore
            onRedirectCallback={onRedirectCallback}
        >
            <App />
        </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
