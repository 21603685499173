import { useMutation, useQueryClient } from 'react-query';
import { useAuth0 } from '../auth';
import { MessagePreviewPartition } from '../pages/TopicDetail/MessagePreview';
import { API_URL, API_VERSION_PATH, doGetRequest, doGetRequestForUrl, doPostRequestToUrl, IAuth } from './abstractAPI';

export const getMessagePreview = async (
    cluster: string,
    topic: string,
    position: string | null,
    messagePreviewPartition: MessagePreviewPartition | null,
    numMessages: number,
    auth: IAuth
) => {
    const path = messagePreviewPartition
        ? `${cluster}/topics/${topic}/consumer?partition=${messagePreviewPartition.partitionName}&offset=${messagePreviewPartition.offset}&messages=${numMessages}&position=SPECIFIC`
        : `${cluster}/topics/${topic}/consumer?position=${position}&messages=${numMessages}`;
    return doGetRequest<Components.Schemas.KafkaMessages>(path, auth);
};

export const getPrincipals = async (cluster: string, auth: IAuth) => {
    return doGetRequest<Components.Schemas.PrincipalList>(`${cluster}/users/principals`, auth);
};

export const getAivenClusterList = async (auth: IAuth): Promise<Components.Schemas.ClusterList> => {
    return doGetRequest(`aiven-clusters`, auth);
};

export const getTeamList = async (auth: IAuth): Promise<Components.Schemas.TeamList> => doGetRequest(`teams`, auth);

export const getAuth0Teams = async (auth: IAuth): Promise<Components.Schemas.TeamList> =>
    doGetRequest(`permissions/teams`, auth);

export const getUserIsTeamAdmin = async (auth: IAuth): Promise<boolean> => doGetRequest(`permissions/teamAdmin`, auth);

export const getTopicList = async (auth: IAuth, cluster: String): Promise<Components.Schemas.TopicList> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics`);

export const getTopicDetails = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Components.Schemas.TopicDetails> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}`, 200);

export const getTopicValueSchema = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Components.Schemas.SchemaDetails> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}/value/schema`, 200);

export const getConsumerGroups = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Array<Components.Schemas.ConsumerGroup>> => {
    return await doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}/consumerGroups`);
};

export const getTopicKeySchema = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Components.Schemas.SchemaDetails> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}/key/schema`, 200);

export const getSchemaRegistryUrl = async (
    auth: IAuth,
    cluster: String
): Promise<Components.Schemas.SchemaRegistryUrl> => {
    return await doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/getSchemaRegistryUrl`);
};

export const getConnectors = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Components.Schemas.ConnectorResponse[]> => {
    return await doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}/connectors`);
};

export const createTopic = async (
    auth: IAuth,
    cluster: String,
    payload: Components.Schemas.TopicInfo
): Promise<Components.Schemas.TopicInfo> =>
    doPostRequestToUrl<Components.Schemas.TopicInfo>(
        `${API_URL}${API_VERSION_PATH}${cluster}/topics`,
        auth,
        JSON.stringify(payload),
        201 // CREATED
    );

export const userIsReadOnly = async (auth: IAuth): Promise<boolean> => {
    const accessToken = await auth.getTokenSilently();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
    const response = await fetch(`${API_URL}${API_VERSION_PATH}permissions`, {
        headers
    });
    const data: Components.Schemas.PermissionResponse = await response.json();
    return data.userType === 'READ_ONLY';
};

export const deleteTopic = async (auth: IAuth, cluster: String, topic: String): Promise<void> => {
    const accessToken = await auth.getTokenSilently();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
    const response = await fetch(`${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}`, {
        headers,
        method: 'DELETE'
    });
    if (response.status === 204) return;
    throw new Error('Unable to delete topic.');
};

export const patchUserPermissions = async (
    auth: IAuth,
    cluster: String,
    topicName: String,
    aclUpdate: Components.Schemas.AclUpdateList
): Promise<void> => {
    const accessToken = await auth.getTokenSilently();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
    const payload = JSON.stringify(aclUpdate);
    const response = await fetch(`${API_URL}${API_VERSION_PATH}${cluster}/users/permissions/${topicName}`, {
        headers,
        body: payload,
        method: 'PATCH'
    });
    if (response.status === 200) return;
    throw new Error(`Unable to patch topic ${topicName} with user permissions ${payload}`);
};

export const changeConfig = async (
    auth: IAuth,
    cluster: string,
    topic: string,
    payload: Components.Schemas.ConfigUpdateRequest
): Promise<void> => {
    const accessToken = await auth.getTokenSilently();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
    const response = await fetch(`${API_URL}${API_VERSION_PATH}${cluster}/topics/${topic}`, {
        headers,
        body: JSON.stringify(payload),
        method: 'PATCH'
    });
    if (response.status === 200) return;
    throw new Error('Could not change config.');
};

export function useChangeConfig(cluster: string, topic: string, closeModalFn?: () => void) {
    const auth = useAuth0();
    const queryClient = useQueryClient();
    return useMutation<void, Error, Components.Schemas.ConfigUpdateRequest>(
        (p) => changeConfig(auth, cluster, topic, p),
        {
            onSuccess: (_) => {
                closeModalFn?.();
                queryClient.invalidateQueries(`topicDetails-${cluster}-${topic}`);
            }
        }
    );
}

export const getKafkaUserList = async (auth: IAuth, cluster: string): Promise<Components.Schemas.KafkaUserList> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/users`);

export const getKafkaUserDetails = async (
    auth: IAuth,
    cluster: string,
    username: string
): Promise<Components.Schemas.KafkaUserDetails> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}${cluster}/users/${username}`);
