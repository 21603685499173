import { ToastProvider } from '@entur/alert';
import { IconButton } from '@entur/button';
import { GridContainer, GridItem } from '@entur/grid';
import { BugIcon, ExternalIcon, LogoNegativeIcon, LogOutIcon } from '@entur/icons';
import { Contrast } from '@entur/layout';
import { Tooltip } from '@entur/tooltip';
import { Heading1, Link } from '@entur/typography';
import React, { createContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import { useAuth0 } from './auth';
import TopicDetailsPage from './pages/TopicDetail/TopicDetailsPage';
import ListTopics from './pages/ListTopics';
import { k, WebData } from './types/ApiTypes';
import { userIsReadOnly } from './api/API';
import { QueryClient, QueryClientProvider } from 'react-query';
import KafkaUsers from './pages/KafkaUsers';
import Clusters from './pages/Clusters';
import { useWindowSize } from './convenience';
import KafkaUserDetailPage from './pages/KafkaUserDetail/KafkaUserDetailPage';
import { getDataDocsUrl } from './config';

const IsReadOnlyUserContext = createContext(true);
const queryClient = new QueryClient();

function App() {
    const auth = useAuth0();
    const { isAuthenticated, loading, loginWithRedirect } = auth;

    useEffect(() => {
        if (!loading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isAuthenticated, loading, loginWithRedirect, auth]);

    const [readOnlyUser, setReadOnlyUser] = useState<WebData<boolean>>({
        kind: k.NotAsked
    });
    useEffect(() => {
        if (readOnlyUser.kind === k.NotAsked && auth.isAuthenticated) {
            setReadOnlyUser({ kind: k.Loading });
            userIsReadOnly(auth).then((data) => setReadOnlyUser({ kind: k.Success, data }));
        }
    }, [auth, readOnlyUser.kind]);

    return (
        <QueryClientProvider client={queryClient}>
            <IsReadOnlyUserContext.Provider value={readOnlyUser.kind === k.Success ? readOnlyUser.data : true}>
                <AppHeaderComponent />
                <div className="App">
                    <ToastProvider>
                        {isAuthenticated && (
                            <Router>
                                {/* <Navigation /> */}
                                <Switch>
                                    <Route
                                        exact
                                        path="/clusters/:clusterPlatform/:cluster/users"
                                        render={({ match }) => (
                                            <KafkaUsers clusterPlatform={match.params.clusterPlatform} />
                                        )}
                                    />
                                    <Route exact path="/clusters/:clusterPlatform/:cluster/users/:user">
                                        <KafkaUserDetailPage />
                                    </Route>
                                    <Route
                                        exact
                                        path={'/clusters/:clusterPlatform/:cluster/:topicName'}
                                        render={({ match }) => (
                                            <TopicDetailsPage
                                                clusterPlatform={match.params.clusterPlatform}
                                                cluster={match.params.cluster}
                                                topicName={match.params.topicName}
                                            />
                                        )}
                                    />
                                    <Route
                                        path="/clusters/:clusterPlatform/:cluster"
                                        render={({ match }) => (
                                            <ListTopics clusterPlatform={match.params.clusterPlatform} />
                                        )}
                                    />
                                    <Route path="/">
                                        <Clusters />
                                    </Route>
                                </Switch>
                            </Router>
                        )}
                    </ToastProvider>
                </div>
            </IsReadOnlyUserContext.Provider>
        </QueryClientProvider>
    );
}

function AppHeaderComponent() {
    const auth = useAuth0();
    const { user, logout } = auth;

    const [width] = useWindowSize();
    const isSmall = () => width < 1100;

    return (
        <Contrast>
            <header>
                <GridContainer>
                    <GridItem small={isSmall() ? 12 : 7}>
                        <Heading1 style={{ margin: '0' }}>
                            <LogoNegativeIcon style={{ marginBottom: '-6px' }} />
                            <a
                                href={'/'}
                                style={{
                                    marginLeft: '2rem',
                                    cursor: 'pointer',
                                    textDecoration: 'inherit',
                                    color: 'inherit'
                                }}
                                aria-label={'home'}
                            >
                                Kafka-admin UI
                            </a>
                        </Heading1>
                    </GridItem>
                    <GridItem
                        small={isSmall() ? 12 : 5}
                        style={{
                            display: 'flex',
                            flexDirection: 'row-reverse',
                            alignItems: 'center'
                        }}
                    >
                        <GridItem small={1} style={{ display: 'flex', alignItems: 'center' }}>
                            <Link
                                href={getDataDocsUrl()}
                                target="_blank"
                                style={{ display: 'inline', marginLeft: '0.5rem' }}
                            >
                                Kafka Docs
                                <ExternalIcon style={{ marginLeft: '0.5rem' }} />
                            </Link>
                        </GridItem>
                        {process.env.NODE_ENV === 'development' && (
                            <Tooltip placement="bottom" content="Copy JWT token">
                                <IconButton
                                    onClick={async () => {
                                        const token = await auth.getTokenSilently();
                                        await navigator.clipboard.writeText(token);
                                    }}
                                >
                                    <BugIcon />
                                </IconButton>
                            </Tooltip>
                        )}
                        {user && user.email.toLowerCase()} &nbsp;&nbsp;
                        <Tooltip placement="bottom" content="Log out">
                            <IconButton onClick={() => logout({ returnTo: window.location.origin })}>
                                <LogOutIcon />
                            </IconButton>
                        </Tooltip>
                    </GridItem>
                    <GridItem small={1} style={{ display: 'flex', alignItems: 'center' }} />
                </GridContainer>
            </header>
        </Contrast>
    );
}

export default App;
export { IsReadOnlyUserContext };
