import React from 'react';
import { GridContainer } from '@entur/grid';
import { useAuth0 } from '../auth';
import { getAivenClusterList } from '../api/API';
import { Loader } from '@entur/loader';
import { BannerAlertBox } from '@entur/alert';
import { useQuery } from 'react-query';
import ClusterListComponent from '../components/ClusterListComponent';
import { useWindowSize } from '../convenience';

const Clusters = () => {
    const auth = useAuth0();
    const aivenClusters = useQuery('aiven-clusters', () => getAivenClusterList(auth));
    const [width] = useWindowSize();
    const isSmall = () => width < 1100;

    if (aivenClusters.isLoading) return <Loader>Loading clusters</Loader>;
    if (aivenClusters.isError)
        return (
            <BannerAlertBox variant={'error'}>
                Error loading clusters{' '}
                <span aria-label={'explosion-img'} role={'img'}>
                    {' '}
                    💥
                </span>
            </BannerAlertBox>
        );

    return (
        <GridContainer rowSpacing="large" spacing="large">
            {aivenClusters.data && aivenClusters.data.clusters.length
                ? ClusterListComponent('aiven-clusters', aivenClusters.data, 'aiven-clusters', isSmall())
                : ''}
        </GridContainer>
    );
};

export default Clusters;
