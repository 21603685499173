import { GridContainer } from '@entur/grid';
import React from 'react';
import DetailedConfigurationPanel from './DetailedConfigurationPanel';
import {
    getConsumerGroups,
    getSchemaRegistryUrl,
    getTopicDetails,
    getTopicKeySchema,
    getTopicValueSchema
} from '../../api/API';
import { useAuth0 } from '../../auth';
import { Loader } from '@entur/loader';
import { BannerAlertBox } from '@entur/alert';
import { useQuery } from 'react-query';
import ConsumerGroupsPanel from './ConsumerGroupsPanel';
import TopicDetails from './TopicDetails';
import { headerNavItem } from '../../components/TopicHeaderNav';
import MessagePreviewPanel from './MessagePreviewPanel';
import SchemaRegistryContainer from './SchemaRegistryContainer';
import { ConnectorsOverview } from './ConnectorsOverview';

type TopicDetailsPageProps = {
    clusterPlatform: string;
    cluster: string;
    topicName: string;
};

const TopicDetailsPage = ({ clusterPlatform, cluster, topicName }: TopicDetailsPageProps) => {
    const auth = useAuth0();
    const topicDetails = useQuery(`topicDetails-${cluster}-${topicName}`, () =>
        getTopicDetails(auth, cluster, topicName)
    );
    const consumerGroups = useQuery(['consumerGroups', cluster, topicName], () =>
        getConsumerGroups(auth, cluster, topicName)
    );
    const valueSchemaDetails = useQuery(`valueSchema-${cluster}-${topicName}`, () =>
        getTopicValueSchema(auth, cluster, topicName)
    );
    const keySchemaDetails = useQuery(`keySchema-${cluster}-${topicName}`, () =>
        getTopicKeySchema(auth, cluster, topicName)
    );
    const schemaRegistryUrl = useQuery(`schemaRegistryUrl-${cluster}`, () => getSchemaRegistryUrl(auth, cluster));

    let groupsData: Components.Schemas.ConsumerGroup[] = consumerGroups.data ?? [];

    function getSchema(schemaDetails?: Components.Schemas.SchemaDetails) {
        return schemaDetails?.schemaType === 'AVRO' ? JSON.parse(schemaDetails.schema) : schemaDetails?.schema;
    }

    let valueSchema = getSchema(valueSchemaDetails.data);
    let keySchema = getSchema(keySchemaDetails.data);

    if (topicDetails.isLoading) return <Loader>Loading</Loader>;
    if (topicDetails.isError) {
        return (
            <BannerAlertBox variant={'error'}>
                {(topicDetails.error as Components.Schemas.ErrorResponse).message}
            </BannerAlertBox>
        );
    }

    if (!topicDetails.data) {
        return null;
    } else {
        return (
            <GridContainer rowSpacing="large">
                {headerNavItem(clusterPlatform, cluster, topicName)}
                <TopicDetails clusterPlatform={clusterPlatform} cluster={cluster} topicDetails={topicDetails.data} />

                <MessagePreviewPanel
                    cluster={cluster}
                    topicName={topicName}
                    messageCount={topicDetails.data?.messageCount}
                    valueSchemaType={valueSchemaDetails.data?.schemaType}
                />

                <ConsumerGroupsPanel groups={groupsData} topicName={topicName} cluster={cluster} />

                <DetailedConfigurationPanel configuration={topicDetails.data.configuration} />

                <SchemaRegistryContainer
                    panelTitle={'Value Schema'}
                    schemaData={valueSchema}
                    schemaUrl={`${schemaRegistryUrl.data?.url}/subjects/${topicName}-value/versions/latest/`}
                />
                <SchemaRegistryContainer
                    panelTitle={'Key Schema'}
                    schemaData={keySchema}
                    schemaUrl={`${schemaRegistryUrl.data?.url}/subjects/${topicName}-key/versions/latest/`}
                />
                <ConnectorsOverview cluster={cluster} topicName={topicName} />
            </GridContainer>
        );
    }
};

export default TopicDetailsPage;
