import React, { ReactNode, useState } from 'react';
import { useAuth0 } from '../../auth';
import { getMessagePreview } from '../../api/API';
import { Loader } from '@entur/loader';
import { BannerAlertBox } from '@entur/alert';
import { GridContainer, GridItem } from '@entur/grid';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import ReactJson from 'react-json-view';
import { InputGroup, SegmentedChoice, SegmentedControl, TextField } from '@entur/form';
import { SuccessButton } from '@entur/button';
import { useQuery } from 'react-query';
import { Modal } from '@entur/modal';

function useMessages(
    cluster: string,
    topic: string,
    position: string | null,
    messagePreviewPartition: MessagePreviewPartition | null,
    numMessages: number
) {
    const auth = useAuth0();
    return useQuery<Components.Schemas.KafkaMessages, Error>(
        ['kafkaMessages', cluster, topic, position, messagePreviewPartition, numMessages],
        () => getMessagePreview(cluster, topic, position, messagePreviewPartition, numMessages, auth),
        {
            enabled: false,
            retry: 0
        }
    );
}

export type MessagePreviewPartition = {
    offset: number;
    partitionName: number;
    endOffset?: number;
};

export enum MessagePreviewType {
    Table,
    Modal
}

const MessagePreview = (props: {
    cluster: string;
    topicName: string;
    messagePreviewPartition: MessagePreviewPartition | null;
    messagePreviewType: MessagePreviewType | null;
}) => {
    const [position, setPosition] = useState<string | null>('earliest');
    const [numMessages, setNumMessages] = useState(1);
    const [previewModalOpen, setModalOpen] = useState(false);

    const messages = useMessages(
        props.cluster,
        props.topicName,
        position || 'earliest',
        props.messagePreviewPartition,
        numMessages
    );
    const isAtEndOffset: boolean =
        props.messagePreviewPartition?.endOffset !== undefined &&
        props.messagePreviewPartition.offset > props.messagePreviewPartition.endOffset;

    function previewMessages(messages: Components.Schemas.KafkaMessage[]) {
        return (
            <Table>
                <TableHead>
                    {messages.length > 0 ? (
                        <TableRow>
                            <HeaderCell style={{ width: '10%' }}>Message offset</HeaderCell>
                            <HeaderCell style={{ width: '10%' }}>Key</HeaderCell>
                            <HeaderCell>Value</HeaderCell>
                        </TableRow>
                    ) : (
                        <TableRow>
                            <DataCell>No further messages found</DataCell>
                        </TableRow>
                    )}
                </TableHead>
                <TableBody>
                    {messages.map((message, i) => {
                        return (
                            <TableRow key={i}>
                                <DataCell>{message.offset}</DataCell>
                                <DataCell>{message.key}</DataCell>
                                <DataCell>
                                    <ReactJson
                                        name={null}
                                        src={JSON.parse(message.value)}
                                        displayDataTypes={false}
                                        collapsed={1}
                                        theme="google"
                                    />
                                </DataCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    const controls = (
        <GridContainer spacing={'medium'}>
            {props.messagePreviewPartition == null && (
                <GridItem small={4} large={4}>
                    <SegmentedControl
                        label={'Which messages do you want to see?'}
                        selectedValue={position}
                        onChange={(v) => setPosition(v)}
                    >
                        <SegmentedChoice value={'earliest'}>First messages</SegmentedChoice>
                        <SegmentedChoice value={'latest'}>Last messages</SegmentedChoice>
                    </SegmentedControl>
                </GridItem>
            )}
            <GridItem small={2} large={2}>
                <InputGroup label={'Number of messages'}>
                    <TextField
                        value={numMessages}
                        disabled={isAtEndOffset}
                        type="number"
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) {
                                setNumMessages(parseInt(e.target.value));
                            } else {
                                setNumMessages(1);
                            }
                        }}
                    />
                </InputGroup>
            </GridItem>
            <GridItem
                small={2}
                large={2}
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'flex-end'
                }}
            >
                <SuccessButton
                    disabled={messages.isLoading || isAtEndOffset}
                    onClick={() => {
                        messages.refetch();
                        setModalOpen(true);
                    }}
                >
                    Start
                </SuccessButton>
            </GridItem>
        </GridContainer>
    );

    let content: ReactNode = null;

    if (messages.isLoading) content = <Loader>Loading messages</Loader>;
    if (messages.isError) content = <BannerAlertBox variant={'error'}>{messages.error.message}</BannerAlertBox>;
    if (messages.data) {
        if (props.messagePreviewType === MessagePreviewType.Modal) {
            content = (
                <Modal
                    size={'extraLarge'}
                    title={'Message preview'}
                    open={previewModalOpen}
                    onDismiss={() => setModalOpen(false)}
                >
                    {previewMessages(messages.data.messages)}
                </Modal>
            );
        } else {
            content = (
                <GridContainer>
                    <GridItem small={12}>{previewMessages(messages.data.messages)}</GridItem>
                </GridContainer>
            );
        }
    }

    return (
        <>
            {controls}
            <br />
            {content}
        </>
    );
};

export default MessagePreview;
