import { ExpandablePanel } from '@entur/expand';
import { GridContainer, GridItem } from '@entur/grid';
import { ButtonGroup, SecondaryButton } from '@entur/button';
import { CopyIcon, ExternalIcon } from '@entur/icons';
import React from 'react';
import ReactJson from 'react-json-view';

type SchemaRegistryContainerProps = {
    panelTitle: string;
    schemaData: any;
    schemaUrl: string;
};

const SchemaRegistryContainer = ({ panelTitle, schemaData, schemaUrl }: SchemaRegistryContainerProps) => {
    let wrappedSchemadata = (() => {
        if (typeof schemaData === 'string') return { schema: `"${schemaData}"` };
        else return schemaData;
    })();

    return (
        <GridItem small={12}>
            <ExpandablePanel title={panelTitle}>
                <GridContainer rowSpacing="medium">
                    <GridItem small={12}>
                        <div className="codeContainer">
                            <ReactJson
                                name={null}
                                src={wrappedSchemadata}
                                displayDataTypes={false}
                                theme="google"
                                collapsed={3}
                            />
                        </div>
                    </GridItem>
                    <GridItem small={12}>
                        <ButtonGroup>
                            <SecondaryButton
                                onClick={() => navigator.clipboard.writeText(JSON.stringify(schemaData, null, 2))}
                            >
                                Copy <CopyIcon />
                            </SecondaryButton>
                            <SecondaryButton
                                as={'a'}
                                href={schemaUrl}
                                target={'_blank'}
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'inherit',
                                    color: 'inherit',
                                    backgroundImage: 'none'
                                }}
                                aria-label={schemaUrl}
                            >
                                Open in Schema Registry <ExternalIcon />
                            </SecondaryButton>
                        </ButtonGroup>
                    </GridItem>
                </GridContainer>
            </ExpandablePanel>
        </GridItem>
    );
};

export default SchemaRegistryContainer;
