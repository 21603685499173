import { GridContainer, GridItem } from '@entur/grid';
import { Heading3 } from '@entur/typography';
import { BreadcrumbItem, BreadcrumbNavigation } from '@entur/menu';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import KafkaUserDetails from '../../components/KafkaUserDetails';

const KafkaUserDetailPage = () => {
    const { clusterPlatform, cluster, user } = useParams<{ clusterPlatform: string; cluster: string; user: string }>();

    return (
        <GridContainer spacing="large">
            <GridItem small={12}>
                <Heading3>
                    <BreadcrumbNavigation>
                        <BreadcrumbItem as={Link} to="/">
                            {clusterPlatform}
                        </BreadcrumbItem>
                        <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}`}>
                            {cluster}
                        </BreadcrumbItem>
                        <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users`}>
                            users
                        </BreadcrumbItem>
                    </BreadcrumbNavigation>
                </Heading3>
            </GridItem>

            <GridItem small={12}>
                <KafkaUserDetails user={user} />
            </GridItem>
        </GridContainer>
    );
};

export default KafkaUserDetailPage;
