import React from 'react';
import { GridItem } from '@entur/grid';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import MessagePreview, { MessagePreviewType } from './MessagePreview';
import { ExpandablePanel } from '@entur/expand';

type ConsumerGroupsViewProps = {
    groups: Components.Schemas.ConsumerGroup[];
    cluster: string;
    topicName: string;
};

const ConsumerGroupsPanel = ({ groups, cluster, topicName }: ConsumerGroupsViewProps) => {
    return (
        <GridItem small={12}>
            <ExpandablePanel title="Consumer Groups">
                {groups.map((group) => (
                    <ExpandablePanel title={group.id} key={group.id}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <HeaderCell>Partition Name</HeaderCell>
                                    <HeaderCell>Offset</HeaderCell>
                                    <HeaderCell>End Offset</HeaderCell>
                                    <HeaderCell>Preview next message(s)</HeaderCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {group.partitions
                                    .sort((a, b) => {
                                        return a.partitionName - b.partitionName;
                                    })
                                    .map((partition) => (
                                        <TableRow key={partition.partitionName}>
                                            <DataCell style={{ verticalAlign: 'top', paddingTop: '2.5em' }}>
                                                {partition.partitionName}
                                            </DataCell>
                                            <DataCell style={{ verticalAlign: 'top', paddingTop: '2.5em' }}>
                                                {partition.offset}
                                            </DataCell>
                                            <DataCell style={{ verticalAlign: 'top', paddingTop: '2.5em' }}>
                                                {partition.endOffset}
                                            </DataCell>
                                            <DataCell>
                                                <MessagePreview
                                                    cluster={cluster}
                                                    topicName={topicName}
                                                    messagePreviewPartition={{
                                                        offset: partition.offset + 1,
                                                        partitionName: partition.partitionName,
                                                        endOffset: partition.endOffset
                                                    }}
                                                    messagePreviewType={MessagePreviewType.Modal}
                                                />
                                            </DataCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </ExpandablePanel>
                ))}
            </ExpandablePanel>
        </GridItem>
    );
};

export default ConsumerGroupsPanel;
