import React from 'react';
import { Heading4, Paragraph } from '@entur/typography';
import { ReactComponent as GrafanaSvg } from './grafana_icon.svg';

type GrafanaLinkProps = {
    cluster: string;
    topic: string;
};

export const GrafanaLink = ({ cluster, topic }: GrafanaLinkProps) => {
    const project = cluster.includes('prod') ? 'entur-prod' : 'entur-test';
    return (
        <Heading4 as={Paragraph}>
            <a
                target="_blank"
                rel="noreferrer"
                href={`https://grafana-ha.entur.org/d/dBloBQN4k/aiven-kafka?orgId=1&from=now-3h&to=now&var-host=All&var-service=${cluster}&var-project=${project}&var-topic_filter=${topic}&refresh=5m`}
            >
                Grafana
                <GrafanaSvg style={{ width: '1.5rem', height: '1rem' }} />
            </a>
        </Heading4>
    );
};
