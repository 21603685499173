import React, { useEffect, useState } from 'react';
import { Modal } from '@entur/modal';
import { Switch } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import { getAuth0Teams, getTeamList, getUserIsTeamAdmin } from '../../api/API';
import { BannerAlertBox } from '@entur/alert';
import { TeamDropdown } from '../../components/TeamDropdown';
import { Loader } from '@entur/loader';
import { useQuery } from 'react-query';
import { useAuth0 } from '../../auth';
import { useUpdateOwnership } from '../../api/ownershipsAPI';

type EditTeamOwnershipProps = {
    cluster: string;
    topicName: string;
    team: Components.Schemas.Team;
    open: boolean;
    close: () => void;
    allowedToModify: boolean;
    updateSuccess: (newTeam: Components.Schemas.Team) => void;
};

const EditTeamOwnership = (props: EditTeamOwnershipProps) => {
    const [team, setTeam] = useState(props.team || 'UNKNOWN');
    const [warningAccepted, setWarningAccepted] = useState(false);
    const auth = useAuth0();

    const allTeamsList = useQuery(['teamList', props.cluster], () => getTeamList(auth));
    const usersAuth0TeamsQuery = useQuery('authTeam', () => getAuth0Teams(auth));
    const userIsTeamAdminQuery = useQuery('userIsTeamAdmin', () => getUserIsTeamAdmin(auth));
    const [userTeams, setUserTeams] = useState<Components.Schemas.Team[]>([]);
    const allowedToModify = userIsTeamAdminQuery.data || usersAuth0TeamsQuery.data?.teams.includes(props.team);

    useEffect(() => {
        if (usersAuth0TeamsQuery.data && allTeamsList.data) {
            if (usersAuth0TeamsQuery.data.teams.includes('DATA')) {
                setUserTeams(allTeamsList.data.teams);
            } else {
                setUserTeams(usersAuth0TeamsQuery.data.teams);
            }
        }
    }, [usersAuth0TeamsQuery.data, allTeamsList.data]);

    const editMutation = useUpdateOwnership(props.cluster, props.topicName, team, props.updateSuccess, props.close);

    if (editMutation.isLoading) {
        return (
            <Modal size={'small'} title={'Edit topic ownership'} open={props.open} onDismiss={props.close}>
                <Loader>Loading</Loader>
            </Modal>
        );
    } else {
        return (
            <Modal size={'small'} title={'Edit topic ownership'} open={props.open} onDismiss={props.close}>
                <TeamDropdown
                    disabled={!allowedToModify}
                    selectedTeam={team}
                    setSelectedTeam={setTeam}
                    availableTeams={userTeams}
                />
                <div style={{ display: 'flex', marginTop: '1rem', marginBottom: '1rem' }}>
                    <Switch checked={warningAccepted} onChange={() => setWarningAccepted(!warningAccepted)} /> I
                    understand that the specified team will replace the current owner.
                </div>
                {editMutation.isError && (
                    <BannerAlertBox variant={'error'}>{editMutation.error?.message}</BannerAlertBox>
                )}
                <PrimaryButton
                    className={'right'}
                    disabled={!warningAccepted}
                    loading={editMutation.isLoading}
                    onClick={() => editMutation.mutate(team)}
                >
                    Save
                </PrimaryButton>
            </Modal>
        );
    }
};

export default EditTeamOwnership;
