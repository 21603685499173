import React, { useState } from 'react';
import { SuccessButton } from '@entur/button';
import { Dropdown } from '@entur/dropdown';
import { Checkbox, InputGroup, TextField } from '@entur/form';
import { GridContainer, GridItem } from '@entur/grid';
import { Heading3, Heading4, Label } from '@entur/typography';
import { BannerAlertBox, useToast } from '@entur/alert';
import { createTopic } from '../api/API';
import { useAuth0 } from '../auth';
import { useMutation, useQueryClient } from 'react-query';
import { Tooltip } from '@entur/tooltip';
import { ValidationInfoIcon } from '@entur/icons';
import { TeamDropdown } from '../components/TeamDropdown';
import { arrToLower } from '../convenience';
import { IAuth } from '../api/abstractAPI';

const validateTopicName = (text: string): 'success' | 'error' | undefined => {
    if (text.length === 0) return undefined;
    if (text.includes(' ')) return 'error';
    return 'success';
};

const validateOrganisationId = (orgId: number): 'success' | 'error' | undefined => {
    if (!orgId) return undefined;
    if (orgId < 0) return 'error';
    return 'success';
};

export const organisationIdLabel = 'External organisation ID';
export const topicNamePlaceHolder = 'topic-name';
export const addTopicButtonText = 'Add topic';

export const retentionUnits = ['days', 'hours', 'ms'];

export const calculateRetentionPeriod = (value: number, unit: string): number => {
    if (value === -1) {
        return value;
    } else {
        switch (unit) {
            case 'days':
                return value * 1000 * 60 * 60 * 24;
            case 'hours':
                return value * 1000 * 60 * 60;
            default:
                return value;
        }
    }
};

const getEnvironments = (cluster: string): Array<Components.Schemas.Environment> => {
    if (cluster.includes('prod')) {
        return ['PRODUCTION'];
    } else {
        return ['DEV', 'STAGING'];
    }
};

const isExternalCluster = (cluster: string): boolean => cluster !== undefined && cluster.indexOf('-ext') >= 0;

type topicInfoCluster = {
    topic: Components.Schemas.TopicInfo;
    cluster: String;
};

type AddTopicContainerProps = {
    cluster: string;
    userTeams: Array<Components.Schemas.Team>;
};

const AddTopicContainer = ({ cluster, userTeams }: AddTopicContainerProps) => {
    const auth: IAuth = useAuth0();
    const queryClient = useQueryClient();
    const [topicName, setTopicName] = useState('');
    const [organisationId, setOrganisationId] = useState(0);
    const [replicationFactor, setReplicationFactor] = useState(3);
    const [partitions, setPartitions] = useState(15);
    const [retention, setRetention] = useState(7);
    const [retentionUnit, setRetentionUnit] = useState('days');
    const [cleanupPolicy, setCleanupPolicy] = useState<'DELETE' | 'COMPACT'>('DELETE');

    const [selectedTeam, setSelectedTeam] = useState<Components.Schemas.Team>(
        userTeams.length > 0 ? userTeams[0] : 'UNKNOWN'
    );
    const [selectedEnvs, setSelectedEnvs] = useState(
        getEnvironments(cluster).map((it) => ({
            key: it as Components.Schemas.Environment,
            value: true
        }))
    );

    const cleanupPolicyOptions: Array<'DELETE' | 'COMPACT'> = ['DELETE', 'COMPACT'];

    const handleAddTopic = (env: Components.Schemas.Environment) => {
        addTopicMutation.mutate({
            topic: {
                name: topicName,
                replicationFactor: replicationFactor,
                partitions: partitions,
                ...(organisationId > 0 && { organisationId: organisationId }),
                retention: calculateRetentionPeriod(retention, retentionUnit),
                cleanupPolicy: cleanupPolicy,
                environment: env,
                team: selectedTeam
            },
            cluster: cluster
        });
    };

    const handleAddTopics = () => {
        const envs = getSelectedEnvironments();
        envs.forEach((env: Components.Schemas.Environment) => {
            handleAddTopic(env);
        });
    };

    const getSelectedEnvironments = (): Components.Schemas.Environment[] =>
        selectedEnvs.filter((it) => it.value!!).map((it) => it.key as Components.Schemas.Environment);

    const envIsSelected = (env: Components.Schemas.Environment) => getSelectedEnvironments().includes(env);

    const changeEnv = (env: Components.Schemas.Environment) => {
        setSelectedEnvs(
            selectedEnvs.map((it) => {
                if (it.key === env) {
                    return { key: it.key, value: !it.value };
                } else {
                    return { key: it.key, value: it.value };
                }
            })
        );
    };

    const addTopicMutation = useMutation(({ topic, cluster }: topicInfoCluster) => createTopic(auth, cluster, topic), {
        onSuccess: () => {
            queryClient.invalidateQueries(['topics']);
            addToast('Topic added!');
        }
    });

    const { addToast } = useToast();

    const canSubmit =
        topicName.length > 0 &&
        replicationFactor > 0 &&
        partitions > 0 &&
        retention >= -1 &&
        (selectedTeam !== 'UNKNOWN' || userTeams.length > 0);

    return (
        <GridContainer spacing="small">
            <GridItem small={12}>
                <Heading3>Add a new topic</Heading3>
            </GridItem>
            <GridItem small={12}>
                <Heading4 style={{ marginTop: '0.5rem' }}>Topic details</Heading4>
            </GridItem>
            {userTeams.length === 0 && (
                <GridItem small={12}>
                    <Label>
                        You do not have permission to edit resources. Please contact Team Data if you want access.
                    </Label>
                </GridItem>
            )}
            {addTopicMutation.isError && (
                <GridItem small={12}>
                    <BannerAlertBox variant="error">
                        {(addTopicMutation.error as Components.Schemas.ErrorResponse).message}
                    </BannerAlertBox>
                </GridItem>
            )}
            <GridItem small={12}>
                <InputGroup
                    label="Topic name"
                    labelTooltip="Can contain lowercase letters, dashes, and numbers"
                    variant={validateTopicName(topicName)}
                >
                    <TextField
                        disabled={userTeams.length === 0}
                        placeholder={topicNamePlaceHolder}
                        value={topicName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setTopicName(e.target.value);
                        }}
                    />
                </InputGroup>
            </GridItem>
            <GridItem small={12}>
                <InputGroup
                    label={'Environment suffix' + (getEnvironments(cluster).length > 1 ? 'es' : '')}
                    labelTooltip={
                        'Cannot be changed. Will be the checked environments of ' +
                        getEnvironments(cluster).map(arrToLower)
                    }
                    variant={'success'}
                >
                    <TextField
                        disabled={true}
                        placeholder={'-<env>'}
                        value={getSelectedEnvironments()
                            .map(arrToLower)
                            .map((it) => '-' + it)}
                    />
                </InputGroup>
            </GridItem>
            {isExternalCluster(cluster) && (
                <GridItem small={12}>
                    <InputGroup
                        label="External organisation ID"
                        labelTooltip="The organisation id of the intended org"
                        variant={validateOrganisationId(organisationId)}
                    >
                        <TextField
                            disabled={userTeams.length === 0}
                            value={organisationId}
                            type="number"
                            min={-1}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                if (e.target.value) {
                                    setOrganisationId(parseInt(e.target.value));
                                }
                            }}
                        />
                    </InputGroup>
                </GridItem>
            )}
            <GridItem small={12}>
                <InputGroup label="Replication factor" labelTooltip="Numeric value, default 3">
                    <TextField
                        disabled={userTeams.length === 0}
                        value={replicationFactor}
                        type="number"
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) {
                                setReplicationFactor(parseInt(e.target.value));
                            } else {
                                setReplicationFactor(1);
                            }
                        }}
                    />
                </InputGroup>
            </GridItem>
            <GridItem small={12}>
                <InputGroup label="Partitions" labelTooltip="Numeric value, default 15">
                    <TextField
                        disabled={userTeams.length === 0}
                        value={partitions}
                        type="number"
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) {
                                setPartitions(parseInt(e.target.value));
                            } else {
                                setPartitions(1);
                            }
                        }}
                    />
                </InputGroup>
            </GridItem>
            {cleanupPolicy === 'DELETE' ? (
                <GridItem small={12}>
                    <InputGroup label="Retention" labelTooltip="Numeric value, default 7 days">
                        <div style={{ display: 'flex' }}>
                            <TextField
                                disabled={userTeams.length === 0}
                                value={retention}
                                type="number"
                                min={1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    if (e.target.value) {
                                        setRetention(parseInt(e.target.value));
                                    } else {
                                        setRetention(1);
                                    }
                                }}
                            />
                            <Dropdown
                                disabled={userTeams.length === 0}
                                items={retentionUnits}
                                value={retentionUnit}
                                onChange={(it) => setRetentionUnit(it?.value || 'days')}
                            />
                        </div>
                    </InputGroup>
                </GridItem>
            ) : null}
            <GridItem small={12}>
                <Dropdown
                    disabled={userTeams.length === 0}
                    label="Cleanup policy"
                    placeholder="Cleanup policy"
                    value={cleanupPolicy}
                    onChange={(x) => {
                        if (x?.value === 'COMPACT') {
                            setRetention(-1);
                        } else {
                            setRetention(7);
                        }
                        setCleanupPolicy(x?.value === 'COMPACT' ? 'COMPACT' : 'DELETE');
                    }}
                    items={cleanupPolicyOptions}
                />
            </GridItem>{' '}
            <GridItem small={12}>
                <TeamDropdown
                    disabled={userTeams.length === 0}
                    selectedTeam={selectedTeam}
                    setSelectedTeam={setSelectedTeam}
                    availableTeams={userTeams}
                />
            </GridItem>
            <GridItem small={12}>
                <InputGroup
                    label="Environments"
                    labelTooltip={`This will add the topic to the selected environments with corresponding suffixes (e.g. "-dev")`}
                    placement="left"
                    styling={{ marginBottom: '0.5rem' }}
                >
                    <GridContainer>
                        {getEnvironments(cluster).map((env) => (
                            <GridItem small={6} key={env}>
                                <Checkbox checked={envIsSelected(env)} onChange={() => changeEnv(env)}>
                                    {env.toLowerCase()}
                                </Checkbox>
                            </GridItem>
                        ))}
                    </GridContainer>
                </InputGroup>
            </GridItem>
            <GridItem small={12}>
                <Tooltip
                    content={`This will add topic "${topicName}" with suffixes ${getSelectedEnvironments()
                        .map(arrToLower)
                        .map((it) => '-' + it)} to ${getSelectedEnvironments().map(arrToLower)}`}
                    placement="right"
                >
                    <SuccessButton
                        style={{ float: 'right', marginTop: '0.5rem' }}
                        onClick={() => handleAddTopics()}
                        disabled={!canSubmit || addTopicMutation.isLoading || userTeams.length === 0}
                    >
                        <ValidationInfoIcon inline /> {addTopicButtonText}
                    </SuccessButton>
                </Tooltip>
            </GridItem>
        </GridContainer>
    );
};

export default AddTopicContainer;
