import { GridItem } from '@entur/grid';
import { Heading3 } from '@entur/typography';
import { BreadcrumbItem, BreadcrumbNavigation } from '@entur/menu';
import { Link } from 'react-router-dom';
import { NavigationCard } from '@entur/layout';
import { OrganizationIcon } from '@entur/icons';
import React from 'react';

const ClusterListComponent = (
    heading: String,
    clusters: Components.Schemas.ClusterList,
    clusterPlatform: string,
    isSmall: boolean
) => {
    return (
        <>
            <GridItem small={12}>
                <Heading3>
                    <BreadcrumbNavigation>
                        <BreadcrumbItem as={Link} to="/">
                            {heading}
                        </BreadcrumbItem>
                        <></>
                    </BreadcrumbNavigation>
                </Heading3>
            </GridItem>
            {clusters.clusters.map((cluster) => (
                <GridItem key={cluster.name} small={isSmall ? 12 : 3}>
                    <NavigationCard
                        title={cluster.name}
                        titleIcon={<OrganizationIcon />}
                        href={`/clusters/${clusterPlatform}/${cluster.name}`}
                    />
                </GridItem>
            ))}
        </>
    );
};

export default ClusterListComponent;
