import { BannerAlertBox } from '@entur/alert';
import { Switch, TextField } from '@entur/form';
import { GridContainer, GridItem } from '@entur/grid';
import { SearchIcon } from '@entur/icons';
import { BreadcrumbItem, BreadcrumbNavigation } from '@entur/menu';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import { Heading3, Label } from '@entur/typography';
import { Link as EnturLink } from '@entur/typography';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { useAuth0 } from '../auth';
import { getAuth0Teams, getTeamList, getTopicList } from '../api/API';
import { Loader } from '@entur/loader';
import { useQuery } from 'react-query';
import AddTopicContainer from './AddTopicContainer';
import { useWindowSize } from '../convenience';

const ListTopics = ({ clusterPlatform }: { clusterPlatform: string }) => {
    const auth = useAuth0();
    const { cluster } = useParams<{ cluster: string }>();

    const topics = useQuery(['topics', cluster], () => getTopicList(auth, cluster));
    const allTeamsList = useQuery(['teamList', cluster], () => getTeamList(auth));
    const usersAuth0TeamsQuery = useQuery('authTeam', () => getAuth0Teams(auth));
    const [userTeams, setUserTeams] = useState<Components.Schemas.Team[]>([]);
    useEffect(() => {
        if (usersAuth0TeamsQuery.data && allTeamsList.data) {
            if (usersAuth0TeamsQuery.data.teams.includes('DATA')) {
                setUserTeams(allTeamsList.data.teams);
            } else {
                setUserTeams(usersAuth0TeamsQuery.data.teams);
            }
        }
    }, [usersAuth0TeamsQuery.data, allTeamsList.data]);

    const [filter, setFilter] = useState('');
    const [showSystemTopics, setShowSystemTopic] = useState(false);
    const [width] = useWindowSize();
    const isSmall = () => width < 1100;

    if (topics.isLoading) return <Loader>Loading topics</Loader>;
    if (topics.isError) return <BannerAlertBox variant={'error'}>Error loading topics in {cluster}</BannerAlertBox>;
    return (
        <GridContainer rowSpacing="medium" spacing="large">
            {isSmall() ? (
                <GridItem small={12}>
                    <AddTopicContainer cluster={cluster} userTeams={userTeams} />
                </GridItem>
            ) : (
                ''
            )}

            <GridItem small={5}>
                <Heading3>
                    <BreadcrumbNavigation>
                        <BreadcrumbItem as={Link} to="/">
                            {clusterPlatform}
                        </BreadcrumbItem>
                        <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}`}>
                            {cluster}
                        </BreadcrumbItem>
                    </BreadcrumbNavigation>
                </Heading3>
            </GridItem>
            <GridItem small={7}>
                <Heading3>
                    <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}/users`}>
                        list users
                    </BreadcrumbItem>
                </Heading3>
            </GridItem>
            <GridItem small={5}>
                <TextField
                    placeholder="Filter by topic or team name"
                    prepend={<SearchIcon inline />}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setFilter(e.target.value);
                    }}
                />
            </GridItem>
            <GridItem small={7} style={{ display: 'flex', alignItems: 'center' }}>
                <Switch checked={showSystemTopics} onChange={() => setShowSystemTopic(!showSystemTopics)} />
                <Label>Show system topics</Label>
            </GridItem>

            <GridItem small={isSmall() ? 12 : 9}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>Topic name</HeaderCell>
                            <HeaderCell>Replication factor</HeaderCell>
                            <HeaderCell>Partitions</HeaderCell>
                            <HeaderCell>Retention</HeaderCell>
                            <HeaderCell>Cleanup policy</HeaderCell>
                            <HeaderCell>Team name</HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topics.data?.topics
                            ?.filter((topic) => {
                                return !(!showSystemTopics && topic.name.startsWith('_'));
                            })
                            ?.filter((topic) => {
                                if (filter === '') {
                                    return true;
                                } else {
                                    const topicIncludesTeam =
                                        topic.team!! && topic.team!.toLowerCase().includes(filter.toLowerCase());
                                    return topic.name.includes(filter) || topicIncludesTeam;
                                }
                            })
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map((topic) => (
                                <TableRow key={topic.name}>
                                    <DataCell>
                                        <EnturLink
                                            as={Link}
                                            to={`/clusters/${clusterPlatform}/${cluster}/${topic.name}`}
                                        >
                                            {topic.name}
                                        </EnturLink>
                                    </DataCell>
                                    <DataCell>{topic.replicationFactor}</DataCell>
                                    <DataCell>{topic.partitions}</DataCell>
                                    <DataCell>{formatRetentionTime(topic.retention)}</DataCell>
                                    <DataCell>{topic.cleanupPolicy}</DataCell>
                                    <DataCell>{topic.team}</DataCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </GridItem>
            {!isSmall() ? (
                <GridItem small={3}>
                    <AddTopicContainer cluster={cluster} userTeams={userTeams} />
                </GridItem>
            ) : (
                ''
            )}
        </GridContainer>
    );
};

function formatRetentionTime(retentionMs: number): String {
    if (retentionMs < 0) return 'Infinite';
    const days = Math.round(retentionMs / (1000 * 60 * 60 * 24));
    const hours = Math.round(retentionMs / (1000 * 60 * 60));
    if (days > 1) return `${days} days`;
    if (days === 1) return `${days} day`;
    if (hours > 1) return `${hours} hours`;
    return `${hours} hour`;
}

export default ListTopics;
export { formatRetentionTime };
