export type Auth = {
    getTokenSilently: () => Promise<string>;
};

export enum k {
    NotAsked,
    Pending,
    Loading,
    Failure,
    Success
}

export interface NotAsked {
    readonly kind: k.NotAsked;
}

export interface Loading {
    readonly kind: k.Loading;
}

export interface Pending {
    readonly kind: k.Pending;
}

export interface Failure<E> {
    readonly kind: k.Failure;
    readonly failure: E;
}

export interface Success<T> {
    readonly kind: k.Success;
    readonly data: T;
}

type RemoteData<E, T> = NotAsked | Loading | Failure<E> | Success<T>;
type ConfirmedRemoteData<E, T> = NotAsked | Pending | Loading | Failure<E> | Success<T>;

export type WebData<T> = RemoteData<String, T>;

export type ConfirmedWebData<T> = ConfirmedRemoteData<String, T>;

export type CleanupPolicy = 'COMPACT' | 'DELETE';
