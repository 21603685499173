import { GridItem } from '@entur/grid';
import { Heading3 } from '@entur/typography';
import { BreadcrumbItem, BreadcrumbNavigation } from '@entur/menu';
import { Link } from 'react-router-dom';
import React from 'react';

export function headerNavItem(clusterPlatform: string, cluster: string, topicName: string) {
    return (
        <GridItem small={11}>
            <Heading3>
                <BreadcrumbNavigation>
                    <BreadcrumbItem as={Link} to="/">
                        clusters
                    </BreadcrumbItem>
                    <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}`}>
                        {cluster}
                    </BreadcrumbItem>
                    <BreadcrumbItem as={Link} to={`/clusters/${clusterPlatform}/${cluster}/${topicName}`}>
                        {topicName}
                    </BreadcrumbItem>
                </BreadcrumbNavigation>
            </Heading3>
        </GridItem>
    );
}
