import { API_URL, API_VERSION_PATH, doGetRequestForUrl, doPostRequestToUrl, IAuth } from './abstractAPI';
import { useAuth0 } from '../auth';
import { useMutation } from 'react-query';

export const getClusterOwnerships = async (auth: IAuth, cluster: String): Promise<Components.Schemas.OwnershipList> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}/ownerships/${cluster}`, 200);

export const getTopicOwnership = async (
    auth: IAuth,
    cluster: String,
    topic: String
): Promise<Components.Schemas.Ownership> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}/ownership/${cluster}/${topic}`, 200);

export const getTeamOwnerships = async (
    auth: IAuth,
    cluster: String,
    teamName: String
): Promise<Components.Schemas.OwnershipList> =>
    doGetRequestForUrl(auth, `${API_URL}${API_VERSION_PATH}/ownerships/${cluster}/${teamName}`, 200);

export const createOwnership = async (
    auth: IAuth,
    payload: Components.Schemas.Ownership
): Promise<Components.Schemas.Ownership> =>
    doPostRequestToUrl<Components.Schemas.Ownership>(
        `${API_URL}${API_VERSION_PATH}/ownership`,
        auth,
        JSON.stringify(payload),
        201 // CREATED
    );

export const updateOwnership = async (
    auth: IAuth,
    cluster: string,
    topic: string,
    team: Components.Schemas.Team
): Promise<void> => {
    const accessToken = await auth.getTokenSilently();
    const headers = {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
    };
    const response = await fetch(`${API_URL}${API_VERSION_PATH}ownership/${cluster}/topic/${topic}/team/${team}`, {
        headers,
        method: 'PUT'
    });
    if (response.status === 200) return;
    throw new Error('Could not change config.');
};

export function useUpdateOwnership(
    cluster: string,
    topic: string,
    team: Components.Schemas.Team,
    updateSuccess: (team: Components.Schemas.Team) => void,
    closeModalFn?: () => void
) {
    const auth = useAuth0();
    return useMutation<void, Error, Components.Schemas.Team>((p) => updateOwnership(auth, cluster, topic, team), {
        onSuccess: (_) => {
            updateSuccess(team);
            closeModalFn?.();
        }
    });
}
