import React from 'react';
import { GridItem } from '@entur/grid';
import { DataCell, HeaderCell, Table, TableBody, TableHead, TableRow } from '@entur/table';
import { Link } from '@entur/typography';
import { ExpandablePanel } from '@entur/expand';

type DetailedConfigurationViewProps = {
    configuration: { key: string; value: string }[];
};

const DetailedConfigurationPanel = (props: DetailedConfigurationViewProps) => {
    const configuration = props.configuration;
    return (
        <GridItem small={12}>
            <ExpandablePanel title="Detailed Configuration">
                {
                    <Table>
                        <TableHead>
                            <TableRow>
                                <HeaderCell>Key</HeaderCell>
                                <HeaderCell>Value</HeaderCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {configuration.map((prop) => (
                                <TableRow key={prop.key}>
                                    <DataCell>
                                        <Link
                                            href={`https://kafka.apache.org/documentation/#${prop.key}`}
                                            target="_blank"
                                        >
                                            {prop.key}
                                        </Link>
                                    </DataCell>
                                    <DataCell>{prop.value}</DataCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                }
            </ExpandablePanel>
        </GridItem>
    );
};

export default DetailedConfigurationPanel;
