import { Dropdown } from '@entur/dropdown';
import React from 'react';

type TeamDropwdownProps = {
    disabled: boolean;
    selectedTeam: Components.Schemas.Team;
    setSelectedTeam: (team: Components.Schemas.Team) => void;
    availableTeams: Components.Schemas.Team[];
};

export const TeamDropdown = ({ disabled, selectedTeam, setSelectedTeam, availableTeams }: TeamDropwdownProps) => {
    return (
        <Dropdown
            disabled={disabled}
            label="Team"
            placeholder="Select a Team"
            value={selectedTeam}
            onChange={(x) => {
                setSelectedTeam((x?.value as Components.Schemas.Team) || 'UNKNOWN');
            }}
            items={availableTeams}
        />
    );
};
