import React, { useState } from 'react';
import { Modal } from '@entur/modal';
import { Dropdown } from '@entur/dropdown';
import { InputGroup, Switch, TextField } from '@entur/form';
import { PrimaryButton } from '@entur/button';
import { useChangeConfig } from '../../api/API';
import { BannerAlertBox } from '@entur/alert';
import { calculateRetentionPeriod, retentionUnits } from '../AddTopicContainer';

type EditRetentionTimeMsProps = {
    existingRetentionTimeMs: number;
    open: boolean;
    close: () => void;
    topic: string;
    cluster: string;
};

const EditRetentionTimeMs = (props: EditRetentionTimeMsProps) => {
    const [retentionValue, setRetentionValue] = useState(props.existingRetentionTimeMs);
    const [unit, setUnit] = useState('ms');
    const [warningAccepted, setWarningAccepted] = useState(false);

    const editMutation = useChangeConfig(props.cluster, props.topic, props.close);

    return (
        <Modal size={'small'} title={'Edit topic retention time'} open={props.open} onDismiss={props.close}>
            <InputGroup label="Retention" labelTooltip="Numeric value, default 7 days">
                <div style={{ display: 'flex' }}>
                    <TextField
                        value={retentionValue}
                        type="number"
                        min={1}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value) {
                                setRetentionValue(parseInt(e.target.value));
                            } else {
                                setRetentionValue(1);
                            }
                        }}
                    />
                    <Dropdown items={retentionUnits} value={unit} onChange={(it) => setUnit(it?.value || 'days')} />
                </div>
            </InputGroup>
            <div style={{ display: 'flex', marginTop: '1rem' }}>
                <Switch checked={warningAccepted} onChange={() => setWarningAccepted(!warningAccepted)} /> I understand
                that changing the retention period of a topic may cause messages to be deleted.
            </div>
            {editMutation.isError && <BannerAlertBox variant={'error'}>{editMutation.error?.message}</BannerAlertBox>}
            <PrimaryButton
                className={'right'}
                disabled={!warningAccepted}
                loading={editMutation.isLoading}
                onClick={() =>
                    editMutation.mutate({
                        retentionMs: calculateRetentionPeriod(retentionValue, unit)
                    })
                }
            >
                Save
            </PrimaryButton>
        </Modal>
    );
};

export default EditRetentionTimeMs;
