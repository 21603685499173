import { DataCell } from '@entur/table';
import React, { useState } from 'react';
import { IconButton } from '@entur/button';
import { DeleteIcon } from '@entur/icons';
import { colors } from '@entur/tokens';
import { Tooltip } from '@entur/tooltip';
import { useQuery, useQueryClient } from 'react-query';
import DeletePermission from './DeletePermission';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '../auth';
import { getAuth0Teams, getTopicDetails } from '../api/API';

type TopicPermissionProps = {
    topicPermission: Components.Schemas.TopicPermission;
    user: string;
};

const TopicPermission = ({ topicPermission, user }: TopicPermissionProps) => {
    const queryClient = useQueryClient();
    const { cluster } = useParams<{ cluster: string }>();
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

    const auth = useAuth0();
    const authTeamsQuery = useQuery(`authTeam-${cluster}`, () => getAuth0Teams(auth));
    const queriedTeams = authTeamsQuery.data ? authTeamsQuery.data.teams : [];
    const userIsDataAdmin = queriedTeams.includes('DATA');

    const topicDetails = useQuery(`topicDetails-${cluster}-${topicPermission.topicName}`, () =>
        getTopicDetails(auth, cluster, topicPermission.topicName)
    );
    const allowedToModify =
        userIsDataAdmin || (topicDetails.data && queriedTeams.includes(topicDetails.data.info.team));

    return (
        <DataCell small={8}>
            <DeletePermission
                cluster={cluster}
                user={user}
                topicPermission={topicPermission}
                isOpen={deleteModalIsOpen}
                close={() => {
                    setDeleteModalIsOpen(false);
                    queryClient.invalidateQueries(`kafkaUserDetails-${user}`);
                }}
            />

            <div>
                {`topic: ${topicPermission.topicName}, permission:
                ${topicPermission.allowConsume ? 'consume/read' : ''}
                ${topicPermission.allowProduce ? 'produce/write' : ''}`}
                <Tooltip placement="bottom" content="Delete ACL">
                    <IconButton
                        disabled={!allowedToModify}
                        onClick={() => setDeleteModalIsOpen(true)}
                        style={{ display: 'inline' }}
                    >
                        <DeleteIcon style={{ color: colors.validation.lava }} />
                    </IconButton>
                </Tooltip>
            </div>
        </DataCell>
    );
};

export default TopicPermission;
